import { useCallback } from 'react'

import Head from 'next/head'

import { Button } from '@nick-mazuk/ui/elements/button'
import { ArrowDown, ChevronUp } from '@nick-mazuk/ui/elements/icon'
import { Text } from '@nick-mazuk/ui/elements/text'
import { CtaButtons } from '@nick-mazuk/ui/marketing/components/cta-buttons'
import { Layout } from 'components/layout'
import Elevator from 'elevator.js'

const ScrollDown = ({ text }: { text: string }): JSX.Element => {
    return (
        <section className='mt-48 text-center'>
            <Text large>{text}</Text>
            <div className='inline-block mx-auto mt-8 text-primary animate-bounce'>
                <ArrowDown width='w-8' />
            </div>
        </section>
    )
}

export default function Custom404(): JSX.Element {
    const elevate = useCallback(() => {
        const elevator = new Elevator({
            mainAudio: '/audio/404/elevator.mp3',
            endAudio: '/audio/404/ding.mp3',
        })
        elevator.elevate()
    }, [])
    return (
        <>
            <Head>
                <link rel='prefetch' href='/audio/404/elevator.mp3' />
                <link rel='prefetch' href='/audio/404/ding.mp3' />
            </Head>
            <Layout title='404'>
                <section className='pt-48 text-center wrapper'>
                    <Text hidden h1>
                        404 - page not found
                    </Text>
                    <Text h5 as='p'>
                        Oops…
                    </Text>
                    <Text h3 as='p' color='text-primary'>
                        This is awkward
                    </Text>
                    <div className='mt-8 mb-12'>
                        <Text>Looks like the page you're looking for has been moved, deleted.</Text>
                    </div>
                    <CtaButtons
                        buttons={[
                            { value: 'Take me home', href: '/' },
                            { value: 'Report the issue', href: '/contact' },
                        ]}
                    />
                </section>
                <section className='mt-24 text-center'>
                    <Text large>Or check out this surprise I have for you.</Text>
                    <div className='inline-block mx-auto mt-8 text-primary animate-bounce'>
                        <ArrowDown width='w-8' />
                    </div>
                </section>
                <ScrollDown text='What is this surprise?' />
                <ScrollDown text={"Well, it's just a little bit further"} />
                <ScrollDown text='In the meantime…' />
                <ScrollDown text='Why did the coffee taste like mud?' />
                <ScrollDown text='Because it was ground a couple of minutes ago' />
                <ScrollDown text='Hah, hah, hah' />
                <ScrollDown text={"Luckily, you're almost there!"} />
                <ScrollDown text='But first…' />
                <ScrollDown text={"Forest Gump has a great soundtrack, doesn't it?"} />
                <ScrollDown text='But do you know what’s Forest Gump’s password is?' />
                <ScrollDown text='1forest1' />
                <ScrollDown text='Hah, hah, hah… that was bad' />
                <ScrollDown text={"Ok, I think we've finally made it"} />
                <ScrollDown text='Just press' />
                <ScrollDown text='the' />
                <ScrollDown text='button' />
                <div className='my-24 text-center'>
                    <Button
                        color='primary'
                        value='Take me to safety'
                        icon={<ChevronUp />}
                        iconPosition='after'
                        onClick={elevate}
                    />
                </div>
            </Layout>
        </>
    )
}
